

#root {
  width: 100%;
  height: 100%;
  
}

.app {
  width: 100%;
  height: 100%;
  display: flex;

  
}

html, body, .app, .app>div {
  min-height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
 

  font-family: 'Roboto Serif', serif;

  
}

p {
  margin: 0px;
}

.app-header {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.6);
  
  font-size: 2rem;
  width: 100%;
  height: 10vh;
}

.nav-item {
  margin: 15px;
  text-decoration: none;
  color: rgba(207, 207, 207, 0.75);
}

.about-me {
  background-image: url("../public/blue-woods.png");
  background-size: cover;
  height: 100%;
  min-height: 100vh !important;
  
  
}

.experience {
  background-image: url("../public/dry-woods.png");
  background-size: cover;
  background-repeat: no-repeat;
  
  
  
}

.portfolio {
  background-image: url("../public/gray-woods.png");
  background-size: cover;
  height: 100%;
  min-height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extracurriculars {
  background-image: url("../public/mt-hood.png");
  background-size: cover;
  height: 100vh;
}



.profile {
  display: flex;
  align-items: center;
  background-color: rgba(110, 110, 110, 0.65);
  padding: 0px;
  
  margin: 5vh;
  margin-top: 15vh;
  border-radius: 5px;;
}

.profile-pic {
  
  max-width: 40%;
  opacity: 85% 
}

.profile-text-container {
  max-width: 100%;
}

.profile-text {
  margin: 5vh;
  font-size: 1.5rem;
}

main {
  margin-top: 20vh;
}

.experience-item {
  background-color: rgba(110, 110, 110, 0.65);
  margin-left: 15vw;
  margin-right: 15vw;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
}

.experience-item-title {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  font-size: 1.5rem;
  
}

.experience-text {
  margin-left: 10px;
}

.thick {
  font-weight: bold;
  margin-right: 10px;
}

.italic {
  font-style: italic;
  margin: 0px;
}

.job-icon {
  width: 115px;
  object-fit: scale-down;
  align-self: center;
}

.education-header {
  margin-left: 15vw;
  color: rgb(175, 175, 175);
}

.education {
  color: rgb(209, 209, 209);
  background-color: rgba(12, 12, 12, 0.7);
}

p {
  margin: 5px;
}


.carousel {
  display: grid;
  grid-template-columns: 3% auto 3%;
  margin-top: 10vh;
  width: 80vw;
  height: 80vh;
  background-color: rgba(110, 110, 110, 0.65);
  opacity: .9;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  
}

.carousel-content {
  max-height: 100%;
  
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.carousel-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  font-size: 1.5rem;
  color: rgb(189, 189, 189);
  border-radius: 20px;
  margin:5%;
  padding: 30px;
  width: 50%;
}

.button-container {
  width: 80%;
}

.link-button {
  width: 100%;
  margin: 10px;
  color: rgb(255, 255, 255);
  background-color: rgba(31, 31, 31, 0.65);
  border: 3px solid rgb(255, 255, 255);
  border-radius: 5px;
  padding: 8px;
  font-size: 1em;
  cursor: pointer;
}

.arrow {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .profile {
    flex-direction:column;
  }

  .profile-pic {
    max-width: 80%;
    margin-top: 25px;
  }

  main {
    margin-top: 15vh;
  }

  .carousel-text {
    width: 70vw;
    font-size: 1.5rem;
  }

  .nav-item {
    margin-left: 20px;
    margin-right: 20px;
    
  }

  
}

@media (max-width: 500px) {
  .app-header {
    font-size: 1.4rem;
  }

  main {
    margin-top: 12vh;
  }
  
  .experience-item {
    flex-direction: column;
    font-size: 1.2rem;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .experience-item-title {
    flex-direction: column;
    align-items: flex-start;
  }

  
  .carousel-text {
    width: 50vw;
    font-size: 1.2rem;
    
  }
  

  .profile-text {
    margin: 10px;
    font-size: 1.2rem;
  }

  .app-header {
    justify-content: space-between;
  }

  .nav-item {
    margin: 10px;
    
  }


}